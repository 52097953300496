































































.modal-content {
  display: none;
  color: #fff !important; }
